.custom-tabledata .modal-dialog{
    /* --bs-modal-width: 1107px !important;
    max-width: var(--bs-modal-width) !important; */
    /* max-width: 670px !important; */

    
}
.custom-tabledata .modal-header {
  border-radius: 40px 40px 0 0 !important;
  border-bottom: none !important;
  justify-content: space-around;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  justify-content: flex-end !important;
}

.custom-tabledata .modal-content {
  border-radius: 40px !important;
  min-width: 900px !important;
}

.custom-tabledata .modal-body {
  border-radius: 0px 0px 40px 40px !important;
  padding: 40px !important;
  
}

.inner-column-tb {
  color: #0f68ff !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 22.4px !important;
}

.close-btn {
    position: absolute;
    right: 30px;
    top: 22px;
    cursor: pointer;
  }
  
  .close-btn:hover {
    transform: scale(1.24);
    cursor: pointer;
    backdrop-filter: blur(11.050000190734863px);
  }

@media (max-width: 992px){
.custom-tabledata .modal-dialog{
    margin-left: 15px;
    margin-right: 15px;
}
}