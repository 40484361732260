.newpass-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 91px;
  padding-right: 91px;
  gap: 24px;
}

.newpass-email-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.newpass-email h5 {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;

  text-align: left;
  color: #0c1230;
  width: 100%;

  margin-top: 50px;
}

.newpass-input {
  border: 1px solid transparent;
  width: 498px;
  height: 72px;
  box-shadow: 0px 2px 6px 0px #2739930f;
  padding-left: 24px;
  border-radius: 20px;
  margin-top: 14px;
}

.newpass-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}
.forgetpass__main .password-match {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  text-align: left;
  color: #00f387;
  padding-top: 12px;
}

.forgetpass__newpass-submit{
  font-family: "Montserrat";
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    border-radius: 20px;
    width: 498px;
    border: transparent;
    height: 60px;
    background-color: #0f68ff;
    color: #ffff;
    margin-top: 32px;
    margin-bottom: 79px;
}

.forgetpass__eyebtn{
  max-width: 498px;
  position: absolute;
  height: 72px !important;
  background-color: transparent !important;
  border: transparent !important;
  right: 0;
  top: 30%;
  bottom: auto;
}

.forgetpass__eyebtn img{
  width: 30px;
  height: 30px;
}

.forgetpass__eyebtn1{
  max-width: 498px;
  position: absolute;
  height: 72px !important;
  background-color: transparent !important;
  border: transparent !important;
  right: 0;
  top: 20%;
  bottom: auto;
}

.forgetpass__eyebtn1 img{
  width: 30px;
  height: 30px;
}