.user-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 51px;
  padding-right: 24px;
}
.profile-name-contain {
  display: flex;
  align-items: center;
  gap: 57px;
}
.prof-name {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 9px;
}
.profile-main {
  background: #bdd4ff;
  border-radius: 60px;
  width: 112px;
  height: 112px;
}
.user-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -1px;
  color: #1d1f2c;
}

.user-edit-btn {
  height: 34px;
  padding: 14px 20px !important;
  border-radius: 8px !important;
  background-color: #ffffff !important;
  border: 1px solid #f0f1f3 !important;
  color: #667085 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22.4px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-transform: capitalize !important;
}

.Referral-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  /* width: 356px; */
  /* height: 34px; */
  padding: 14px 20px;
  border-radius: 8px;
  border: 1px solid #f0f1f3;
  background-color: #ffffff;
  margin-top: 8px;
}

.Referral-box p {
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  color: #667085;
}
.link-copy {
  cursor: pointer;
}

.profile-info-rhs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}
.user-heading {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5.38px;
}
.user-value {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 5.38px;
}

.user-heading p {
  font-size: 14px;
  font-weight: 600;
  line-height: 18.97px;
  text-align: left;
  color: #000000;
}

.user-value .value-p1 {
  color: #6f6f6f;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 18.97px;
  text-align: right;
}
.user-profile-cards {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  padding-top: 31px;
}
.btns-table-main {
  display: flex;
  gap: 15px;
}
.btn-referral-table {
  border: 1px solid #f0f1f3 !important;
  padding: 14px 20px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22.4px !important;
  color: #667085 !important;
  cursor: pointer;
  text-transform: capitalize !important;
  background-color: #ffffff !important;
}
.btn-account-table {
  border: 1px solid #f0f1f3 !important;
  padding: 14px 20px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22.4px !important;
  color: #667085 !important;
  text-transform: capitalize !important;
  cursor: pointer;
  background-color: #ffffff !important;
}
.btn-active-tb {
  border: 1px solid #0f68ff !important;
  padding: 14px 20px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22.4px !important;
  color: #0f68ff !important;
  text-transform: capitalize !important;
  cursor: pointer;
  background-color: #ffffff !important;
}
.btn-account-table:hover {
  color: #0f68ff !important;
  border: 1px solid #0f68ff !important;
}
.btn-referral-table:hover {
  color: #0f68ff !important;
  border: 1px solid #0f68ff !important;
}

.user-row3 {
  border-radius: 20px !important;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3) !important;
  background: var(--Black-0---Primary, #fff) !important;
  padding: 28px 24px;
  margin-top: 31px;
  margin-bottom: 110px;
  overflow-x: auto;
}

.empty-dash {
  /* font-family: Montserrat; */
  font-size: 18px;
  font-weight: 900 !important;
  

}

@media (max-width: 1300px) {
  .user-head {
    flex-wrap: wrap;
    gap: 30px;
  }
}
@media (max-width: 992px) {
  .user-head {
    flex-wrap: wrap;
    gap: 30px;
  }

  .user-profile-cards {
    flex-wrap: wrap;
  }
  .profile-info-rhs {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .user-head {
    justify-content: center;
  }
  .profile-name-contain {
    justify-content: center;
  }
  .profile-name-contain {
    flex-wrap: wrap;
    gap: 20px;
  }
  .Referral-box,
  .referral-edit,
  .user-edit-btn {
    width: 100%;
  }
  .Referral-box {
    justify-content: space-between;
  }
}
@media (max-width:430px) {
  .user-head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 51px;
    padding-right: 0px !important;
}
}
