p {
  padding: 0;
  margin: 0;
}

.profile-head {
  color: #1d1f2c;

  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 36.4px */
  letter-spacing: -1px;
}

.profile-whole {
  padding-top: 24px;
  padding-left: 46px;
  height: 100vh;
}

.profile-div {
  padding-top: 35px;
  padding-bottom: 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  
}
.profile-txt {
  color: #1d1f2c;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.referral-contain {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 7px;
}
.input-label {
  color: #667085;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.68px;
  margin-bottom: 7px;
}

.referral-input {
  border: 1px solid rgba(157, 170, 199, 0.25) !important;
  border-radius: 11px !important;
  /* background: #f4f8ff !important; */
  padding-top: 23px !important;
  padding-bottom: 23px !important;
  padding-left: 23px !important;
  font-family: "Montserrat", sans-serif !important;
  width: 360px !important;
  height: 56px;
  position: relative;
}

.referral-input::placeholder {
  color: rgba(102, 112, 133, 0.7) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 135% !important; /* 18.9px */
}
.copy-img {
  position: absolute;
  padding-right: 20px;
  top: 35%;
  right: 0;
  cursor: pointer;
}
.copyimg{
  cursor: pointer;
}

.referral-group {
 
  position: relative;
}

.div2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 35px;
}
.div2-r2 {
  display: flex;
  /* align-items: flex-start; */
  gap: 60px;
}
.div2-r3 {
  display: flex;
  gap: 60px;
}
.div3-btn {
  padding-top: 45px;
}
.profile-connect-btn {
  border-radius: 8px !important;
  background: var(--Blue-Text, #0f68ff) !important;
  display: flex !important;
  height: 48px !important;
  padding: 14px 24px !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--Neutral-Colors-100, #fff) !important;
  text-align: center !important;

  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 18.2px !important; /* 111.111% */
  letter-spacing: 0.005em !important;
  text-transform: capitalize !important;
  width: 148px !important;

}

.profile-img {
  height: 112px;
  width: 112px;
  border-radius: 60px;
}
.wallet-profile {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-bottom: 35px;
}
.wallet-address-head {
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  color: #000;
}
.wallet-value {
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  color: #667085;
}
.email-group{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.btn-sign-email {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18.9px !important;
  color: #0f68ff !important;
  width: 360px !important;
  height: 56px !important;
/* padding: 23.44px 114px 22.56px 117px; */
/* padding: 23px 12px 23px 23px; */
  border-radius: 11px !important ;
  border: 1px solid #0F68FF !important;
  text-transform: none !important;
  
}
.copy-input-contain{
  position: relative;
}
@media (max-width: 768px) {
  .div2-r2 {
    flex-direction: column;
    gap: 30px;
  }
  .div2-r3 {
    flex-direction: column;
    gap: 30px;
  }
  .referral-input {
    width: 100% !important;
  }
  .copy-input-contain{
    width:100%;
      }
  .btn-sign-email{
    width: 100% !important;
  }
  .profile-whole {
    padding-left: 0px;
  }
  .div3-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
  }
  .profile-connect-btn{
    width: 100% !important;
  }
  .wallet-profile{
    flex-wrap: wrap;
  }
}
