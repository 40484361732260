 canvas {
    /* border: 1px dotted red; */
    width: 100% !important;
    max-width: 1000px;
    height: auto !important;
    
    /* margin-left: 15px ; */
  } 
  
  .chart-container {
    position: relative;
    margin: auto;
    margin-top: 45px;
    width: 100%;
    /* width: 33vw; */
    height: 330px;
    /* height: 40vh;
    width: 45vw; */
  }

@media(max-width:1200px)
{
    .chart-container{
        width: 100%;
    }
}
  /* @media(max-width:992px)
  {
    .chart-container{
        width: 90vw;
    }
  }
  @media(max-width:768px)
  {
    .chart-container{
        width: 80vw;
    }
  }
  @media(max-width:500px)
  {
    .chart-container {
        
        width:80vw;
        
      }
  } */