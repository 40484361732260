.close__btn__common {
    border-radius: 10px;
    margin-top: 5px;
    border: none;
    padding: 10px 12px !important;
    color: #fff;
    font-family: AktivGrotesk-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    height: 35px;
  }
  
  .custom-close-button__error {
    background: #f36960;
  }
  
  .custom-close-button__success {
    background: #41c588;
  }