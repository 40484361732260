.ClaimProfits__main {
  margin-left: 20px;
  margin-top: 20px;
  min-height: 100vh;
}
.ClaimProfits__main-heading_div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.ClaimProfits__main-heading {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -1px;
  text-align: left;
}
.search-input_div{
  position: relative;
}
.search-input{
  width: 248px;
height: 43px;
border-radius: 10px;
padding-left: 42px; 
border: 1px solid #F0F1F3;
/* position: relative; */
}
.search-input__image{
  position: absolute;
  transform: translate(-50% , -50%);
  left: 8%;
  bottom: 0;

}

.ClaimProfits__main-heading h3 {
  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -1px;
  text-align: left;
  color: #1d1f2c;
  padding-bottom: 5px !important;
}

.ClaimProfits__main-heading p {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  color: #000000;
}

.claimprofit__text-h6 {
  color: #090912 !important;
}

.ClaimProfits__main-heading span {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #667085;
}

.ClaimProfits__main-content {
  display: flex;
  flex-wrap: wrap;
  /* display: grid;
    grid-template-columns: repeat(3, 1fr); */
  border-radius: 15px;
  border: 1px;
  gap: 30px;
}

.ClaimProfits__main-cards img {
  width: 100%;
  height: 100%;
}

.ClaimProfits__main-cards {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 296px;
  height: 100%;
  border: 1px solid #e2e5ef;
  border-radius: 15px;
  /* cursor: pointer; */
}

.ClaimProfits__main-cards-hero {
  margin-left: 15px;
  margin-right: 15px;
}

.ClaimProfits__main-cards-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 17.74px;
}

.ClaimProfits__main-cards-heading h6 {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  text-align: left;
  color: #0f68ff;
}

.ClaimProfits__main-cards-heading p {
  font-family: "Montserrat";
  font-size: 8px;
  font-weight: 500;
  line-height: 9.75px;

  text-align: center;
  color: #667085;
}

.ClaimProfits__main-cards-heading span {
  color: #1d1f2c;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  text-align: center;
}
.ClaimProfits__main-cards-hero-card {
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
  background-color: transparent;
}
.ClaimProfits__main-cards-txt {
  width: 131px;
  height: 42px;
  border-radius: 8px;
  border: 0.5px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ClaimProfits__main-cards-txt p {
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 500;
  line-height: 9.75px;
  text-align: center;
  color: #667085;
  text-transform: uppercase;
}

.ClaimProfits__main-cards-txt h6 {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
  text-align: center;
  /* padding-top: 8px; */
  color: #1d1f2c;
}

.ClaimProfits__main-cards-btn {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 24px;
  margin-top: 10px;
}

/* .ClaimProfits__main-cards button {
    color: #0F68FF;
    border: 1px solid #0F68FF;
    border-radius: 8px;
    width: 100%;
    height: 42px;
    padding: 10px, 24px, 10px, 24px;
    margin-top: 10px;
   
    background-color: #0F68FF0D;
    

    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.005em;
} */

.btn-active {
  color: #0f68ff;
  border: 1px solid #0f68ff;
  border-radius: 8px;
  width: 100%;
  height: 42px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: 10px;
  /* margin-left: 20px; */
  background-color: #0f68ff0d;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.005em;
}
.btn-inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #0f68ff;
  border-radius: 8px;
  width: 100%;
  height: 42px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: 10px;
  background-color: #0f68ff;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.005em;
}

/* .ClaimProfits__main-cards:hover{
    scale: 1.02;
}

.ClaimProfits__main-cards img:hover{
    scale: 1;
} */

@media (max-width: 768px) {
  /* .ClaimProfits__main-cards{
        width: 100%;

    }
    .ClaimProfits__main-cards img{
        width: 100%;


    } */
    .ClaimProfits__main-heading_div{
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
    }
}
.claimProfit__noNft-main{
  width: 100%;
  height: 798px;
  border-radius: 10px;
  background-color: #FFFFFF;
  border: 1px solid #E2E5EF;
}
.claimProfit__noNft-main-txt{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 15px;
}
.claimProfit__noNft-main-txt h6{
  /* font-family: Montserrat; */
font-size: 20px;
font-weight: 600;
line-height: 26px;
/* text-align: left; */
color: #1D1F2C;
}
.claimProfit__noNft-main-txt p{
  /* font-family: Montserrat; */
font-size: 12px;
font-weight: 400;
line-height: 15.6px;
text-align: center;
color: #667085;
}
.claimProfit__noNft-main br{
  display: none;
}
@media (max-width:430px) {
  .claimProfit__noNft-main {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    background-color: #FFFFFF;
    border: 1px solid #E2E5EF;
}
.claimProfit__noNft-main br{
  display: block;
}
}