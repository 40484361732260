.Verification__main{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.verification__logo{
    width: 55px;
    height: 55px;
    margin: 32px 0px;
}
.verification__logo-tick{
    margin-top: 30px;
    width: 500px;
    height: 500px;
}
.Verification__main p{
    font-family: "Montserrat";
font-size: 32px;
font-weight: 600;
line-height: 51.2px;
text-align: center;
color: #0C1230;
margin-top: 10px;
}
.Verification__main button{
    width: 497px;
    height: 72px;
    background-color: #0F68FF;
    color: #FFFFFF;
    /* font-family: Montserrat; */
font-size: 18px;
font-weight: 700;
line-height: 20px;
text-align: center;
    border-radius: 20px;
    border: none;
    /* margin-bottom: 40px; */
    margin-top: 30px;
}
@media (max-width: 768px) {
    .Verification__main button{
        width: 250px;
        margin-top: 50px;
    }
    .verification__logo-tick{
        width: 200px;
        height: 200px;
        margin-top: 50px;
    }
    .Verification__main p{
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 50px;
    }
}