p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px !important;
}
.row {
  --bs-gutter-x: 0rem !important;
}

.Mint-whole{
  /* text-align: center; */
}
.web3-img {
  max-width: 502px;
  height:314px;
  /* width: 502px;
  height: 314px; */
  /* position: relative; */
  /* z-index: 1200; */
}
.web3-img-mb,
.circle-back-mb {
  display: none;
}
.circle-back {
  position: absolute;
  top: 10%;
  right: 7%;
}
.tester{
  background-color: red;
}
.tester-2{
  background-color: #1cec18;
}

.Heading-20k {
  
  font-size: 80px;
  font-weight: 700;
  line-height: 97.52px;
  letter-spacing: -0.04em;
  margin-bottom: 10px !important;
  /* text-align: left; */
  color: #1f6af9;
}
.sub-update-new{
  margin-top: 20px;
}
.sub-txt {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 21.94px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #464d6a;
}
/* .sub-txt span {
  color: var(--Neutral-Colors-600, #464d6a);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
} */
.Section-1-20 {
  /* padding-left: 116px !important; */
  /* padding-right: 110px; */
  display: flex;
  align-items: center;
  padding-top: 65.5px;
  align-items: center;
  width: 100%;
  /* position: relative; */
  z-index: 0 !important;
  /* gap: 73px; */
  justify-content: center;
  text-align: left;
}
/* .Section1__left{
  margin-right: 73px;
} */

.label-txt {
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  letter-spacing: -0.02em;
  text-align: left;
  cursor: pointer;
  color: #1f6af9;
}
.label-txt-50k {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  letter-spacing: -0.02em;
  color: #003184;
  cursor: pointer;

}
.label-txt-100k {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  letter-spacing: -0.02em;
  color: #141414;
  cursor: pointer;

}
.M3-txt {
  color: #1f6af9;

  font-size: 22.7px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.454px;
  cursor: pointer;
}
.Total-sales {
  color: #bcbcbc;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.form-check-label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.print-check_main {
  margin-right: 34px;
  z-index: 2;
}

.printing-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 2px solid #f0f0f0;
  background: #fff;
  padding: 16px 30px;
  width: 510px;
  cursor: pointer;
  z-index: 2;
}
.print-check2 {
  margin-top: 12px;
}
.form-check {
  display: flex !important;
  align-items: center !important;
  padding-left: 10px !important;
}
.form-check-input[type="checkbox"] {
  border-radius: 1rem !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  width: 24px !important;
  height: 24px !important;
}
.form-check-input:checked {
  background-color: #0f68ff !important;
  border: var(--bs-border-width) solid transparent !important;
}
.form-check-input:focus {
  box-shadow: none !important;
}

.Row-2-mint {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 173px;
  /* position: relative; */
  /* z-index: 99999; */
}
.padd-main{
  padding: 20px;
}
.Row-2-inner-mint {
  border-radius: 10px;
  border: 2px solid #f0f0f0;
  background: #fff;
  display: flex;
  width: 794px;
  /* padding: 16px 24px 16px 36px; */
  padding: 25px;
  justify-content: space-between;
  align-items: center;
  border-radius: 28px;
  /* width: 100%; */
}
.Row2-LHS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.Row2-RHS {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.img-txt {
  display: flex;
  align-items: center;
  /* gap: 6px; */
}

.q-value-contain-mint,
.Last-price-contain-mint {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 4px;
}

.List-price-contain {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 8px;
}

.quantity-contain {
  display: flex;
  align-items: center;
  gap: 24px;
}

.arrows-contain {
  display: flex;
  flex-direction: column;
}
.Lhs-label {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  line-height: 14.63px;
}
.listed-status {
  border: 2px solid #1cec18;
  background: #1cec18;
  width: 15px;
  height: 15px;
  border-radius: 10px;
}
.unlisted-status{
  border: 2px solid #ff0909;
  background: #f70202;
  width: 15px;
  height: 15px;
  border-radius: 10px;

}
.Lhs-value {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.T-quantity {
  color: #b7b7b7;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0px;
}
.Mint-btn {
  border-radius: 10px !important;
  background: #0f68ff !important;
  display: flex !important;
  height: 60px !important;
  padding: 0px 24px !important;
  justify-content: center !important;
  align-items: center !important;
  width: 300px !important;
  font-weight: 600 !important;
}

.Row-3 {
  border-radius: 40px;
  background: var(
    --Blue-Gradient,
    linear-gradient(72deg, #0555ec 0%, #2b74ff 100%)
  );
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 49px 610.47px 64px 88px;

  /* margin-left: 110px !important; */
  /* margin-right: 110px !important; */
  position: relative;
}
.Stay-txt {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 51px; /* 106.25% */
  letter-spacing: -1px;
  background: var(
    --Left-Gradient,
    linear-gradient(234deg, #fff 0%, #dfeaff 125.07%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 5px;
}
.Stay-des {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 138.889% */
  letter-spacing: -0.18px;
}
.btn-join-telegram {
  margin-top: 20px !important;
  border-radius: 80px !important;
  background: var(--Neutral-Colors-100, #fff) !important;
  display: flex !important;
  padding: 18px 28px !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;

  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important; /* 111.111% */
  color: #1562f4 !important;
}

.Latest-img {
  position: absolute;
  top: -23%;
  left: 68%;
}

.footer-mint {
  padding-left: 124px;
  padding-right: 150.44px;
  padding-top: 195px;
  padding-bottom: 64px;
}

@media (max-width: 1400px) {
  .Latest-img {
    position: absolute;
    top: -15%;
    left: 54%;
  }
  .Stay-des br {
    display: none;
  }
  .Stay-txt br {
    display: none;
  }
  /* .Section-1 {
    position: relative;
    z-index: 1700;
  } */
  .circle-back {
    position: absolute;
    right: 0%;
    top: 8%;
  }
}
@media (max-width: 1200px) {
  /* .Section-1, */
  .Row-2-mint {
    /* padding-left: 50px !important; */
    /* padding-right: 50px; */
  }
  .Latest-img {
    display: none;
  }
  .Row-3 {
    margin-left: 50px !important;
    margin-right: 50px !important;
    padding: 20px;
    justify-content: center;
  }
  .printing-check {
    width: 100%;
  }
}
@media(max-width:992px)
{
  /* .Section-1-20{
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
  } */
  .web3-img{
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .sub-txt br {
    display: none;
  }
  .Heading {
    font-size: 40px;
    letter-spacing: -1.6px;
  }
  .form-check-label {
    gap: 7.78px;
  }
  .form-check-input[type="checkbox"] {
    width: 18.682px !important;
    height: 18.682px !important;
  }
  .sub-txt,
  .sub-txt span {
    font-size: 14px;
    letter-spacing: -0.28px;
  }
.Heading-20k{
  font-size: 60px;
}
  .q-value-contain {
    flex-direction: row;
    gap: 16px;
  }
  .Section-1-20 {
    padding-top: 40px;
    padding-left: 15px !important;
    padding-right: 15px;
    gap: 50px;
    flex-wrap: wrap;
  }
  .Row-2-mint {
    padding-left: 15px !important;
    padding-right: 15px;
    padding-top: 71px;
    padding-bottom: 80px;
  }
  .Row-3 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  /* .web3-img {
    display: none;
  } */
  .web3-img-mb {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .circle-back {
    display: none;
  }
  .circle-back-mb {
    position: absolute;
    top: 56%;
    /* right: 7px;

    bottom: 21px; */
    display: block;
  }
  .Row-2-inner-mint {
    width: 100%;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    padding: 0px;
    border: none;
    border-radius: 0;
    background-color: transparent;
  }

  .Row2-LHS {
    flex-direction: column;
    gap: 25px;
  }
  .Row2-RHS {
    justify-content: center;
  }
  .Stay-des {
    font-size: 13px;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.13px;
  }
  .Stay-txt {
    font-size: 25px;

    line-height: 30px; /* 120% */
    letter-spacing: -0.328px;
    padding-bottom: 10px;
  }
  .btn-join-telegram {
    width: 100% !important;
    padding: 10px 20px !important;
    border-radius: 26.23px !important;
    background: var(--Neutral-Colors-100, #fff) !important;

    font-size: 14px !important;

    line-height: 14px !important;
    margin-top: 12px !important;
  }
  .M3-txt,
  .label-txt {
    font-size: 17.67px;

    letter-spacing: -0.353px;
  }
  .Total-sales {
    font-size: 12.455px;
  }
  .printing-check {
    border-radius: 10px;
    border: 1.557px solid #f0f0f0;
    background: #fff;
    padding: 12.455px 23.353px;
  }
  .print-check2 {
    margin-top: 10px;
  }
  .footer-mint {
    padding-left: 77px;
    padding-right: 77px;
    padding-top: 75px;
    padding-bottom: 52px;
  }
}
