.modal {
  --bs-modal-box-shadow: 0px 2px 12px 0px #27399314 !important;
  /* backdrop-filter: blur(11.050000190734863px) !important; */
}
.main-section-signin {
  border-radius: 0px 0px 15px 15px;
}

/* .modal-whole{
    z-index: 0;
  } */

.modal-whole-signin .modal-dialog {
  max-width: 680px;
}

.modal-whole-signin .modal-content {
  border-radius: 40px !important;
  max-width: 680px;
  /* margin-top: 6% !important; */
  /* height: 600px; */
  box-shadow: 0px 2px 12px 0px #27399314 !important;
}
.modal-whole-signin .modal-body {
  background-color: white;
  border-top: none;
  color: #000;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.modal-whole-signin .modal-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: none !important;
  justify-content: end;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.modal-whole-signin .Web3modal__heading {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* text-align: center; */
  /* margin-bottom: 29px; */
}
.modal-whole-signin .Web3modal__heading h1 {
  font-family: "Montserrat";
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -1px;
  text-align: center;
  color: #0c1230;
  margin-bottom: 16px !important;
}

.modal-whole-signin .Web3modal__heading p {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #464d6a;
}

.modal-whole-signin .Web3modal__heading-google {
  margin-top: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 6px 0px #2739930f;
  width: 498px;
  height: 68px;
  border-radius: 20px;
  margin-bottom: 18px !important;
}
.modal-whole-signin .Web3modal__heading-google:hover {
  transform: scale(1.05);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
  transition: 0.3s ease-in;
}
.modal-whole-signin .google-2 {
  margin-bottom: 48px;
}

.modal-whole-signin .Web3modal__heading-google img {
  width: 29px;
  height: 29px;
}

.modal-whole-signin .Web3modal__heading-google p {
  padding-left: 16px;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #464d6a;
}

.modal-whole-signin .web3modal__text {
  /* width: 498px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.modal-whole-signin .web3modal__email {
  /* width: 498px; */
  height: 106px;
  text-align: center;
  margin-bottom: 24px;
}

.modal-whole-signin .web3modal__email h5 {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #0c1230;

  /* width: 498px; */
}

.modal-whole-signin .web3modal__email input {
  border: 1px solid transparent;
  width: 498px;
  height: 72px;
  box-shadow: 0px 2px 6px 0px #2739930f;

  /* box-shadow: 0px -1px 2px 0px #2739930A; */

  border-radius: 20px;
  margin-top: 14px;
}

.modal-whole-signin .shadow-btn {
  padding-left: 24px;
}

.modal-whole-signin .web3modal__btn button {
  border-radius: 20px;
  background-color: #0f68ff;
  border: none;
  width: 496px;
  height: 72px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}

.modal-whole-signin .web3modal__btn p {
  color: #464d6a;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;

  padding-top: 18px;
  width: 100%;
  text-align: center;
}
.modal-whole-signin .web3modal__btn span,
.modal-whole-signin .web3modal__btn .p2 {
  color: #0f68ff;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  cursor: pointer;
  padding-top: 8px;
  width: 100%;
  text-align: center;
}
.modal-whole-signin .web3modal__btn span:hover,
.modal-whole-signin .web3modal__btn .p2:hover {
  text-decoration: underline;
}

.modal-whole-signin .connect-p {
  padding-bottom: 21px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.modal-whole-signin .subText {
  font-size: 15px;
  line-height: 25px;
  text-align: center;
}

.modal-whole-signin .checkbox-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 14px;
}

.modal-whole-signin .checkbox-design {
  width: 14.114px;
  height: 14.114px;
  margin-right: 5.6px;
}

.modal-whole-signin .choose-txt {
  padding-top: 36px;
  padding-bottom: 11px;
  text-align: center;
}

.modal-whole-signin .meta-common-signin {
  margin-left: 7.56px;
  margin-top: 8.29px;
}

.modal-whole-signin .meta-diff {
  /* margin-top: 8.29px; */
  /* width: 100%; */
}

.modal-whole-signin .meta-cards {
  padding-bottom: 46px;
  display: flex;
  gap: 8px;
}

.modal-whole-signin .close-btn-img {
  position: absolute;
  right: 30px;
  top: 22px;
  cursor: pointer;
}

.modal-whole-signin .close-btn-img:hover {
  transform: scale(1.24);
  cursor: pointer;
  backdrop-filter: blur(11.050000190734863px);
}

.modal-whole-signin .meta-common-signin:hover {
  transform: scale(1.05);
  cursor: pointer;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important; */
  /* border-radius: 10px; */
}

.modal-whole-signin .meta-diffs:hover {
  transform: scale(1.05);
  cursor: pointer;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important; */
  /* border-radius: 10px; */
  margin-top: 8.29px;
}
.web3modal__email {
  position: relative;
}
.web3modal__email button {
  background: transparent;
  position: absolute;
  border: none !important;
  transform: translate(-50%);
  bottom: 15%;
  right: 0;
}
.web3modal__email button img {
  width: 30px;
  height: 30px;
}

.meta-new {
  transition: 0.3s ease-in !important;
}
.meta-connect-new {
  transition: 0.3s ease-in !important;
}

@media (max-width: 992px) {
  /* .meta-cards{
      display: flex;
      flex-direction: column;
      align-items: center !important;
      justify-content: center !important;
    } */

  .modal-whole-signin .modal-content {
    height: auto;
    margin-top: 9%;
  }
  .web3modal__btn {
    width: 100%;
  }
  .modal-whole-signin .wallet-main-img {
    /* width: 245px; */
    width: 100%;
    height: 68px;
    text-align: center;
  }
  .modal-whole-signin .web3modal__email {
    /* width: 300px; */
    width: 100%;
  }
  .modal-whole-signin .web3modal__email input {
    /* width: 300px; */
    width: 100%;
  }
  .modal-whole-signin .web3modal__btn button {
    /* width: 300px; */
    width: 100%;
  }
}

@media (max-width: 550px) {
  .modal-whole-signin .modal-content {
    height: auto;
    margin-top: 13%;
  }
  .modal-whole-signin .web3modal__text {
    align-items: flex-start;
  }
  .modal-whole-signin .web3modal__email input {
    /* width: 300px; */
    width: 100%;
  }
  .modal-whole-signin .web3modal__btn button {
    /* width: 300px; */
    width: 100%;
  }
  .modal-whole-signin .web3modal__btn {
    width: 100%;
    text-align: center;
  }
  .modal-whole-signin .meta-cards {
    display: flex;
    flex-direction: column;
  }

  /* .web3modal__email button{
    max-width: 498px;
    position: absolute;
    height: 72px;
    background-color: transparent;
    border: transparent;
    right: 15%;
    top: 64%;
    bottom: auto;
  
  } */

  /* .web3modal__email input{
      width: 100%;
      text-align: left;
    }
    .web3modal__email{
      text-align: left;
    } */
}

@media (max-width: 430px) {
  .modal-whole-signin .web3modal__text {
    /* width: 498px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 41.73px !important;
  }
  .modal-whole-signin .Web3modal__heading h1 {
    font-family: "Montserrat";
    font-size: 28px !important;
    font-weight: 600;
    line-height: 32.65px !important;
    letter-spacing: -0.54px !important;
    text-align: center;
    color: #0c1230;
    margin-bottom: 8.35px !important;
  }
  .modal-whole-signin .Web3modal__heading p {
    font-family: "Montserrat";
    font-size: 12px !important;
    font-weight: 500;
    line-height: 16.32px !important;
    text-align: center;
    color: #464d6a;
  }
  .modal-whole-signin .Web3modal__heading-google {
    margin-top: 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 2px 6px 0px #2739930f;
    width: 100% !important;
    height: 36px !important;
    margin-bottom: 10px !important;
    border-radius: 10.88px !important;
    gap: 8.51px !important;
  }
  .modal-whole-signin .Web3modal__heading-google img {
    width: 15.93px !important;
    height: 16px !important;
  }
  .modal-whole-signin .Web3modal__heading-google p {
    padding: 0px !important;
    font-family: "Montserrat";
    font-size: 12px !important;
    font-weight: 500;
    line-height: 10.88px !important;
    text-align: center;
    color: #464d6a;
  }
  .modal-whole-signin .Web3modal__heading-google p {
    font-family: "Montserrat";
    font-size: 10px !important;
    font-weight: 500;
    line-height: 10.88px !important;
    text-align: center;
    color: #464d6a;
  }
  .modal-whole-signin .meta-cards {
    display: flex;
    flex-direction: row;
  }
  .modal-whole-signin .meta-cards {
    padding-bottom: 25px !important;
    display: flex;
    gap: 6px !important;
  }
  .modal-whole-signin .web3modal__email h5 {
    font-family: "Montserrat";
    font-size: 12px !important;
    font-weight: 700;
    line-height: 10.88px !important;
    text-align: left;
    color: #0c1230;
    /* width: 498px; */
  }
  .modal-whole-signin .web3modal__email input {
    border: 1px solid transparent;
    width: 100% !important;
    height: 46px !important;
    box-shadow: 0px 2px 6px 0px #2739930f;
    /* box-shadow: 0px -1px 2px 0px #2739930A; */
    border-radius: 10.88px !important;
    margin-top: 7.5px !important;
  }
  .modal-whole-signin .shadow-btn {
    padding-left: 13.06px !important;
    padding-right: 40px !important;
  }
  .modal-whole-signin .email-signin-input {
    width: 100% !important;
    height: 56.5px !important;
    text-align: center;
    margin-bottom: 15px !important;
    font-family: Montserrat;
    font-size: 10px !important;
    font-weight: 500 !important;
    line-height: 10.88px !important;
    text-align: left;
    color: #464d6a !important;
  }
  .password-signin-input {
    width: 100% !important;
    height: 56.5px !important;
    text-align: center;
    margin-bottom: 25px !important;
    font-family: Montserrat;
    font-size: 10px !important;
    font-weight: 500 !important;
    line-height: 10.88px !important;
    text-align: left;
    color: #464d6a !important;
  }
  .password-signin-input::placeholder {
    font-family: Montserrat;
    font-size: 10px !important;
    font-weight: 500 !important;
    line-height: 10.88px !important;
    text-align: left;
    color: #464d6a !important;
  }
  .web3modal__email button img {
    width: 15px;
    height: 15px;
  }
  .modal-whole-signin .web3modal__btn button {
    border-radius: 10.88px !important;
    background-color: #0f68ff;
    border: none;
    width: 100% !important;
    height: 46px !important;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 10px !important;
    font-weight: 700;
    line-height: 10.88px !important;
    text-align: center;
  }
  .modal-whole-signin .web3modal__btn .p2 {
    color: #0f68ff;
    font-weight: 500;
    font-size: 10px !important;
    line-height: 16.32px !important;
    cursor: pointer;
    padding-top: 9.83px !important;
    width: 100%;
    text-align: center;
  }
  .modal-whole-signin .web3modal__btn p {
    color: #464d6a;
    font-weight: 500;
    font-size: 10px !important;
    line-height: 16.32px !important;
    padding-top: 18px;
    width: 100%;
    text-align: center;
    padding-top: 3px !important;
  }
  .modal-whole-signin .web3modal__btn p span {
    color: #464d6a;
    font-weight: 500;
    font-size: 10px !important;
    line-height: 16.32px !important;
    padding-top: 18px;
    width: 100%;
    text-align: center;
    padding-top: 3px !important;
  }
  .modal-whole-signin .modal-content {
    height: auto;
    margin-top: 13%;
    /* margin-left: 30px !important;
  margin-right: 30px !important; */
  }
  .modal-whole-signin .web3modal__text {
    margin-bottom: 0px !important;
  }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
    padding: 30px !important;
  }
}
@media (max-width: 375px) {
  .main-section-signin .modal.show .modal-dialog {
    transform: none;
    margin: 30px !important;
  }
  .modal-whole-signin .meta-cards {
    padding-bottom: 26px !important;
    display: flex;
    gap: 6px !important;
    flex-wrap: wrap;
  }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
    padding: 20px !important;
  }
}
@media (max-width: 320px) {
  .modal-body {
    padding-left: 44px !important;
    padding-right: 44px !important;
    padding-bottom: 35.73px !important;
    padding-top: 0px !important;
  }
}
