/* canvas {
    border: 1px dotted green;
  } */
  
  .Line-chart-container {
    position: relative;
    margin: auto;
    height: 40vh;
    width: 80vw;
  }


   /* canvas {
    border: 1px dotted red;
    
  }  */
  
  .Line-chart-container {
    position: relative;
    margin: auto;
    margin-top: 45px;
    width: 33vw;
    width: 100%;
    height: 340px;
    /* height: 40vh;
    width: 45vw; */
  }

@media(max-width:1200px)
{
    .Line-chart-container{
        width: 100%;
    }
}
  /* @media(max-width:992px)
  {
    .Line-chart-container{
        width: 90vw;
    }
  }
  @media(max-width:768px)
  {
    .Line-chart-container{
        width: 80vw;
    }
  }
  @media(max-width:500px)
  {
    .Line-chart-container {
        
        width:80vw;
        
      }
  } */