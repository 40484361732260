.SignUp-PG {
    background: url("/public/Images/Sign\ UpImage.svg");
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: top;
  }
  
  @media (max-width: 800px) {
    .SignUp-PG {
      background-position: center;
    }
  }
  