/* .modal.show .modal-dialog{
    max-width: 670px !important;
    
} */
.modal-content {
  /* height: 671px !important; */
  border-radius: 25px !important;
}
.verification__main-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.verification__main-modal img {
  width: 312.52px;
  height: 312.19px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.verification__main-modal p {
  /* font-family: Montserrat; */
  font-size: 32px;
  font-weight: 600;
  line-height: 51.2px;
  text-align: center;
  color: #0c1230;
  padding-bottom: 50px !important;
}
.verification__main-modal button {
  width: 497px;
  height: 72px;
  color: #ffffff;
  border: none;
  margin-bottom: 50px !important;
  background-color: #0f68ff;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;
  border: none;
}
.modal-wholes .modal-dialog{
  max-width: 680px !important;
}
@media (max-width: 430px) {
  .modal-wholes .modal-content{
    transform: none;
    /* margin-left: 30px !important;
    margin-right: 30px !important; */
  }
  .verification__main-modal button {
    width: 100% !important;
    height: 46px !important;
    color: #ffffff;
    border: none;
    margin-bottom: 0px !important;
    background-color: #0f68ff;
    font-size: 10px !important;
    font-weight: 700;
    line-height: 10.88px !important;
    text-align: center;
    border-radius: 10.88px !important;
    border: none;
  }
  .verification__main-modal p {
    font-family: Montserrat;
    font-size: 28px !important;
    font-weight: 600;
    line-height: 32.65px !important;
    text-align: center;
    letter-spacing: -0.54px !important;
    color: #0c1230;
    padding-bottom: 28.31px !important;
}
.verification__main-modal img {
    width: 150px !important;
    height: 150px !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}
}
@media (max-width: 375px) {
    .modal-wholes .modal-content {
        transform: none;
        /* margin-left: 20px !important;
        margin-right: 20px !important; */
    }
}
@media (max-width: 320px) {
    .modal-wholes .modal-content {
        transform: none;
        /* margin-left: 15px !important;
        margin-right: 15px !important; */
    }
}