.not-verified-Modal .modal-content{
    border-radius: 40px;
   
}
.not-verified-pad{
    padding-top: 10px;
}
.not-v button{
    margin-bottom: 20px;
    margin-top: 0px;
}
.not-v h1{
    font-size: 32px;

}
@media (min-width:992px) {

    .not-verified-Modal .modal-content{
        --bs-modal-width: 698px !important;

       
    }

   
    
}