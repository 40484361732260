.modal-whole-web .main-section-wallet {
  border-radius: 0px 0px 15px 15px;
}
.modal-whole-web .modal-content {
  border-radius: 140px !important;
}

.modal-whole-web .modal-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: none !important;
  justify-content: space-around;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  justify-content: flex-end !important;
}

.connect-p {
  padding-bottom: 21px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.subText {
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  max-width: 459px;
  color: #464D6A;
}

.checkbox-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 14px;
}

.checkbox-design {
  width: 14.114px;
  height: 14.114px;
  margin-right: 5.6px;
}

.choose-txt {
  padding-top: 36px;
  padding-bottom: 11px;
  text-align: center;
}

.meta-common {
  margin-left: 7.56px;
  margin-top: 8.29px;
}

.meta-diff {
  width: 209.44px;
  height: 116.71px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--Blue-Text, #0F68FF);
  border-radius: 12px;
  gap: 7.99px;
  /* margin-top: 8.29px; */
}
.meta-diff img{
  width: 31.99px;
  height: 31.99px;
}
.wallet-img_txt{
  font-family: "Montserrat";
font-size: 14px;
font-weight: 600;
line-height: 18.97px;
text-align: center;
color: #1D1F2C;
}

.meta-cards {
  padding-bottom: 35.29px;
}

.close-btn-img {
  position: absolute;
  right: 30px;
  top: 22px;
  cursor: pointer;
}

.close-btn-img:hover {
  transform: scale(1.24);
  cursor: pointer;
  backdrop-filter: blur(11.050000190734863px);
}

.meta-common:hover {
  transform: scale(1.02);
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px;
}

.meta-diff:hover {
  transform: scale(1.02);
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px;
}

@media (max-width: 500px) {
  .wallet-main-img {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .meta-common{
    display: none;
  }
  .meta-diff{
    width: 207 !important;
    height: 65px;
    margin-top: 25px !important
    ;
  }
  .meta-diff img{
    width: 24px;
    height: 24px;
  }
  .wallet-img_txt{
    font-size: 12px;
    font-weight: 600;
    line-height: 16.26px;
    text-align: Center;
  }
  /* .meta-diff img{
    width: 207px;
    height: 65px;
  } */
  .choose-txt{
    display: none;
  }
  .connect-p{
    /* font-family: Montserrat; */
font-size: 20px !important;
font-weight: 600;
line-height: 27.1px;

  }
  .subText{
    /* font-family: Montserrat; */
font-size: 12px;
font-weight: 400;
line-height: 16.26px;
/* text-align: center; */
color: #464D6A;
  }
}
