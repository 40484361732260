.modal {
  --bs-modal-box-shadow: 0px 2px 12px 0px #27399314 !important;
  /* backdrop-filter: blur(11.050000190734863px) !important; */
}
.main-section-signup {
  border-radius: 0px 0px 15px 15px;
}

.modal-whole-signup .modal-dialog {
  max-width: 680px;
}

.modal-whole-signup .modal-content {
  border-radius: 40px !important;
  max-width: 680px;
  height: 770px;
  box-shadow: 0px 2px 12px 0px #27399314 !important;
  /* margin-bottom: 6% !important; */
}
.modal-whole-signup .modal-body {
  background-color: white;
  border-top: none;
  color: #000;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.modal-whole-signup .modal-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: none !important;
  justify-content: end;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.modal-whole-signup .Web3modal__heading {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* text-align: center; */
  margin-bottom: 18px;
}
.modal-whole-signup .Web3modal__heading h1 {
  font-family: "Montserrat";
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -1px;
  text-align: center;
  color: #0c1230;
  padding-bottom: 16px;
}

.modal-whole-signup .Web3modal__heading p {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #464d6a;
}

.modal-whole-signup .Web3modal__heading-google {
  margin-top: 29px;
  display: flex;

  justify-content: center;
  align-items: center;
  cursor: pointer;

  box-shadow: 0px 2px 6px 0px #2739930f;

  /* box-shadow: 0px -1px 2px 0px #2739930A; */
  width: 498px;
  height: 68px;
  border-radius: 20px;
}

.modal-whole-signup .Web3modal__heading-google img {
  width: 29px;
  height: 29px;
}
.modal-whole-signup .Web3modal__heading-google:hover {
  transform: scale(1.05);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
  transition: 0.3s ease-in;
}

.modal-whole-signup .Web3modal__heading-google p {
  padding-left: 16px;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #464d6a;
}

.modal-whole-signup .web3modal__text {
  /* width: 498px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 136px;
}

.modal-whole-signup .web3modal__signup-email {
  /* width: 498px; */
  height: 106px;
  text-align: center;
  margin-bottom: 24px;
}

.modal-whole-signup .web3modal__signup-email h5 {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #0c1230;

  /* width: 498px; */
}

.modal-whole-signup .web3modal__signup-email input {
  border: 1px solid transparent;
  width: 498px;
  height: 72px;
  box-shadow: 0px 2px 6px 0px #2739930f;

  /* box-shadow: 0px -1px 2px 0px #2739930A; */

  border-radius: 20px;
  margin-top: 16px;
}

.modal-whole-signup .shadow-btn {
  padding-left: 24px;
}

.modal-whole-signup .web3modal__btn button {
  border-radius: 20px;
  background-color: #0f68ff;
  border: none;
  width: 496px;
  height: 72px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}

.modal-whole-signup .web3modal__btn p span {
  color: #0f68ff;
}
.modal-whole-signup .web3modal__btn p {
  color: #464d6a;
  cursor: pointer;
  margin-top: 18px !important;
  width: 100%;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
}
.modal-whole-signup .connect-p {
  padding-bottom: 21px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.modal-whole-signup .subText {
  font-size: 15px;
  line-height: 25px;
  text-align: center;
}

.modal-whole-signup .checkbox-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 14px;
}

.modal-whole-signup .checkbox-design {
  width: 14.114px;
  height: 14.114px;
  margin-right: 5.6px;
}

.modal-whole-signup .choose-txt {
  padding-top: 36px;
  padding-bottom: 11px;
  text-align: center;
}

.modal-whole-signup .meta-common {
  margin-left: 7.56px;
  margin-top: 8.29px;
}

.modal-whole-signup .meta-cards {
  padding-bottom: 35.29px;
  display: flex;
  gap: 8px;
}

.modal-whole-signup .close-btn-img {
  position: absolute;
  right: 30px;
  top: 22px;
  cursor: pointer;
}

.modal-whole-signup .close-btn-img:hover {
  transform: scale(1.24);
  cursor: pointer;
  backdrop-filter: blur(11.050000190734863px);
}

.modal-whole-signup .meta-common:hover {
  transform: scale(1.02);
  cursor: pointer;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important; */
  /* border-radius: 10px; */
}

.modal-whole-signup .meta-diffs:hover {
  transform: scale(1.02);
  cursor: pointer;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important; */
  /* border-radius: 10px; */
  margin-top: 8.29px;
}

.meta-new {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 6px 0px #2739930f;
  border-radius: 20px;
  height: 68px;
  width: 245px;
}
.meta-connect-new {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 6px 0px #2739930f;
  border-radius: 20px;
  height: 68px;
  width: 245px;
}

.meta-new:hover {
  transform: scale(1.02);
  cursor: pointer;
  margin-top: 8.29px;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
}
.meta-connect-new:hover {
  transform: scale(1.02);
  cursor: pointer;
  margin-top: 8.29px;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
}

.web3modal__signup-email button {
  max-width: 498px;
  position: absolute;
  height: 72px;
  background-color: transparent;
  border: transparent;
  right: 15%;
  top: 66%;
  bottom: auto;
}
.web3modal__signup-email button img {
  width: 30px;
  height: 30px;
}

@media (max-width: 992px) {
  .modal-whole-signup .modal-content {
    height: auto;
    margin-top: 9%;
  }
  .web3modal__btn {
    width: 100%;
  }
  .modal-whole-signup .wallet-main-img {
    /* width: 245px; */
    width: 100%;
    height: 68px;
    text-align: center;
  }
  .modal-whole-signup .web3modal__signup-email {
    /* width: 300px; */
    width: 100%;
  }
  .modal-whole-signup .web3modal__signup-email input {
    /* width: 300px; */
    width: 100%;
  }
  .modal-whole-signup .web3modal__btn button {
    /* width: 300px; */
    width: 100%;
  }
  .web3modal__signup-email button {
    top: 59%;
  }
}

@media (max-width: 550px) {
  .modal-whole-signup .modal-content {
    height: auto;
    margin-top: 11%;
  }
  .modal-whole-signup .web3modal__text {
    align-items: flex-start;
  }
  .modal-whole-signup .web3modal__signup-email input {
    /* width: 300px; */
    width: 100%;
  }
  .meta-connect-new,
  .meta-new {
    width: 100%;
  }
  .modal-whole-signup .Web3modal__heading-google {
    width: 100%;
  }
  .modal-whole-signup .web3modal__btn button {
    /* width: 300px; */
    width: 100%;
  }
  .modal-whole-signup .web3modal__btn {
    width: 100%;
    text-align: center;
  }
  .modal-whole-signup .meta-cards {
    display: flex;
    flex-direction: column;
  }
  .web3modal__signup-email button {
    top: 63%;
  }
}
@media (max-width: 430px) {
  .modal-dialog {
    margin: 0px !important;
  }
  .modal-whole-signup .modal-content {
    height: 506.57px !important;
    margin-top: 11%;
}
  .modal-whole-signup .Web3modal__heading h1 {
    font-family: "Montserrat";
    font-size: 28px !important;
    font-weight: 600 !important;
    line-height: 32.65px !important;
    letter-spacing: -1px;
    text-align: center;
    color: #0c1230;
    padding-bottom: 8.13px !important;
  }
  .modal-whole-signup .Web3modal__heading {
    margin-bottom: 10px !important;
  }
  .modal-whole-signup .Web3modal__heading p {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    line-height: 16.32px;
    text-align: center;
    color: #464d6a;
  }
  .modal-whole-signup .Web3modal__heading-google {
    margin-top: 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 2px 6px 0px #2739930f;
    /* box-shadow: 0px -1px 2px 0px #2739930A; */
    width: 100% !important;
    height: 36px;
    border-radius: 10.88px !important;
    gap: 8.51px !important;
  }
  .modal-whole-signup .Web3modal__heading-google img {
    width: 15.95px !important;
    height: 16px !important;
  }
  .modal-whole-signup .Web3modal__heading-google p {
    font-family: "Montserrat";
    font-size: 10px;
    font-weight: 500;
    line-height: 10.88px !important;
    text-align: center;
    color: #464d6a;
    padding: 0px !important;
  }
  .modal-whole-signup .meta-cards {
    display: flex;
    flex-direction: row;
  }
  .modal-whole-signup .meta-cards {
    padding-bottom: 25px !important;
    display: flex;
    gap: 6px !important;
  }
  .meta-new {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 6px 0px #2739930f;
    border-radius: 10.88px !important;
    height: 36px !important;
    width: 132px !important;
    gap: 8.47px !important;
    padding: 10px, 26px, 10px, 26px !important;
  }
  .meta-new img {
    width: 78.31px !important;
    height: 15.57px !important;
  }
  .meta-connect-new {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 6px 0px #2739930f;
    border-radius: 10.88px !important;
    height: 36px !important;
    width: 132px !important;
  }
  .meta-connect-new img {
    width: 100.55px !important;
    height: 16px !important;
  }
  .modal-whole-signup .web3modal__signup-email h5 {
    font-family: "Montserrat";
    font-size: 12px !important;
    font-weight: 700;
    line-height: 10.88px !important;
    text-align: left;
    color: #0c1230;
    /* width: 498px; */
  }
  .modal-whole-signup .web3modal__signup-email {
    margin-bottom: 14.24px !important;
  }
  .modal-whole-signup .web3modal__signup-email input {
    border: 1px solid transparent;
    width: 498px;
    height: 38px !important;
    box-shadow: 0px 2px 6px 0px #2739930f;
    /* box-shadow: 0px -1px 2px 0px #2739930A; */
    border-radius: 10.88px !important;
    margin-top: 7.5px !important;
  }
  .modal-whole-signup .shadow-btn {
    padding-left: 13.6px !important;
    padding-right: 40.6px !important;
    font-family: Montserrat;
    font-size: 10px !important;
    font-weight: 500 !important;
    /* line-height: 10.88px !important; */
    text-align: left;
    color: #464d6a !important;
  }
  .modal-whole-signup .web3modal__signup-email input {
    border: 1px solid transparent;
    width: 100% !important;
    height: 46px !important;
    box-shadow: 0px 2px 6px 0px #2739930f;
    /* box-shadow: 0px -1px 2px 0px #2739930A; */
    border-radius: 10.88px !important;
    margin-top: 8px !important;
    display: flex;
  }
  .modal-whole-signup .email-input {
    /* width: 498px; */
    height: 56.5px !important;
    text-align: center;
    margin-bottom: 15px !important;
  }
  .modal-whole-signup .password-input {
    /* width: 498px; */
    height: 57.59px !important;
    text-align: center;
    margin-bottom: 25px !important;
  }
  .web3modal__signup-email button {
    max-width: 498px;
    position: absolute;
    /* height: 81px; */
    background-color: transparent;
    border: transparent;
    right: 16% !important;
    top: 57.85% !important;
    bottom: auto;
  }
  .web3modal__signup-email button img {
    width: 15px !important;
    height: 15px !important;
  }
  .modal-body {
    padding-left: 44px !important;
    padding-right: 44px !important;
    padding-bottom: 51.73px !important;
    padding-top: 0px !important;
  }
  .modal-whole-signup .web3modal__btn button {
    border-radius: 20px;
    background-color: #0f68ff;
    border: none;
    width: 100% !important;
    height: 46px !important;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 10px !important;
    font-weight: 700;
    line-height: 10.88px !important;
    text-align: center;
    border-radius: 10.88px !important;
    padding: 14.15px, 20.68px, 14.15px, 20.68px !important;
    margin-bottom: 10px !important;
    display: flex;
        justify-content: center;
        align-items: center;
  }
  .modal-whole-signup .web3modal__btn p {
    margin-top: 0px !important;
  }
  .modal-whole-signup .web3modal__btn p {
    color: #464d6a;
    cursor: pointer;
    margin-top: 0px !important;
    width: 100%;
    text-align: center;
    font-family: Montserrat;
    font-size: 10px !important;
    font-weight: 500 !important;
    line-height: 16.32px !important;
}
  .web3modal__btn p span {
    color: #0f68ff !important;
  }
  

  .modal-whole-signup .web3modal__text{
    margin-bottom: 0px !important;
  }
  /* .modal-whole-signup .web3modal__signup-email input::placeholder{
  font-family: Montserrat;
font-size: 10px !important;
font-weight: 500 !important;
line-height: 10.88px !important;
text-align: left;
color: #464D6A !important;
} */
}
