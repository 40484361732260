.ListedNFT__main {
  margin-left: 22px;
  height: 100%;
  margin-top: 21.5px !important;
  /* max-width: 948px !important; */

  margin-right: 158px;
}
.css-7muzc9 {
  background-color: #ffffff !important;
}
.ListedNFT__main-top {
  display: flex;
  gap: 8px;
  justify-content: end;
}

.search-input-listed {
  width: 248px;
  height: 43px;
  border-radius: 10px;
  padding-left: 42px;
  border: 1px solid #f0f1f3;
  position: relative;
}

.search-input__img {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 8%;
  bottom: 0;
}

#dropdown-basic_listed {
  background-color: #ffffff !important;
  border: 1px solid #f0f1f3;
  width: 106px !important;
  height: 42px !important;
  border-radius: 8px !important;

  font-family: "Montserrat" !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: center;
  color: #667085;
}
.dropdown-toggle::after {
  margin-left: 8px !important;
}
.dropdown-menu.show {
  /* min-width: 106px; */

  border: 1px solid #f0f1f3 !important;
  background-color: #F7F7F7 !important;
  opacity: 90%;
  backdrop-filter: blur(31px);
}
.dropdown-menu:active{
    background-color: #f8f8f8 !important;
}
.dropdown-item{
    color: #667085 !important;
}
.dropdown-item:hover{
    /* background-color: transparent; */
    color: #1D1F2C  !important;
}
#dropdown-basic-listed-2 {
  background-color: #ffffff !important;
  border: 1px solid #f0f1f3;
  width: 96px !important;
  height: 42px !important;
  border-radius: 8px !important;

  font-family: "Montserrat" !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: center;
  color: #667085;
}
#dropdown-basic-listed-body{
    background-color: #ffffff !important;
    border: 1px solid #f0f1f3;
    width: 170px !important;
    height: 42px !important;
    border-radius: 8px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Montserrat" !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: center;
    color: #667085;
}
.dropdown-listed-body-box{
    display: flex;
    gap: 10px;
}
.dropdown-listed-body-box input{
    width: 70px;
    height: 42px;
    border: 1px solid #F0F1F3;
    background-color: #FFFFFF; 
    border-radius: 8px;
    /* text-align: center; */
    padding-left: 17px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dropdown-listed-body-box input::placeholder{
  color: #66708580;

}
.dropdown-listed-body-box-text{
  font-family: Montserrat;
font-size: 12px;
font-weight: 600;
line-height: 14.63px;
/* text-align: left; */
color: #1D1F2C;
display: flex;
justify-content: center;
align-items: center;
}
.dropdown-listed-body-box-btn{
  width: 173px;
  background-color: #0F68FF;
  height: 34px;
  border-radius: 8px;
  font-family: Montserrat;
font-size: 12px;
font-weight: 600;
line-height: 15.6px;
text-align: center;
color: #FFFFFF;
border: none;
}
.ListedNFT__main-hero{
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    border-radius: 5px;
    width: 100%;
    background-image: url("/public/Images/ListedNFT/Img bg.svg");
    height: 196px;
    background-repeat: no-repeat;
}
.ListedNFT__main-hero-left {
width: 100%;
  height: 196px;
  border-radius: 10px;
  padding-left: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  
}
.ListedNFT__main-hero-right img{
    height: 196px !important;
    max-width: 300px;
}
.ListedNFT__main-hero h1 {
  /* font-family: Montserrat; */
  font-size: 28px;
  font-weight: 600;
  line-height: 36.4px;
  letter-spacing: -1px;
  text-align: left;
  color: #0f68ff;
}

.ListedNFT__main-hero-txt {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.ListedNFT__main-hero-txt_head {
  /* font-family: Montserrat; */
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: center;
}
.ListedNFT__main-hero-txt_head span {
  /* font-family: Montserrat; */
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: center;
}
.ListedNFT__main-hero-txt_text {
  /* font-family: Montserrat; */
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #667085;
}
.ListedNFT__main-body {
  margin-top: 45px !important;
  flex-wrap: wrap;
}
.ListedNFT__main-body-txt {
  width: 70px;
  height: 22px;
  border: 0.5px solid #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
}
.ListedNFT__main-cards {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 296px;
  height: 100%;
  border: 1px solid #e2e5ef;
  border-radius: 15px;
}

.ListedNFT__cards-body p {
  /* font-family: Montserrat; */
  font-size: 8px;
  font-weight: 500;
  line-height: 9.75px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
}
.ListedNFT__cards-body p span {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
  text-align: left;
  color: #1d1f2c;
}
.Listed__main-cards-btn {
  margin-left: 15px !important;
  margin-top: 17px !important;
  margin-bottom: 17px !important;
  margin-right: 15px;
}

.Listed__main-cards-btn button {
  color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  width: 100%;
  height: 42px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: 10px;
  background-color: transparent;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.005em;
}
.ListedNFT__main-cards:hover  .Listed__main-cards-btn button{
  color: #ffffff;
  border: 1px solid #0f68ff;
  border-radius: 8px;
  width: 100%;
  height: 42px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: 10px;
  background-color: #0f68ff;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.005em;
  transition: 0.5s ease-in;
}
.ListedNFT__main-top-dropdown{
  display: flex;
  gap: 8px;
}
@media (max-width:1024px) {
  .ListedNFT__main{
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
@media (max-width:768px) {
  .ListedNFT__main-hero h1{
    font-size: 20px;
  }
  .ListedNFT__main-hero-txt_head{
    font-size: 12px;
  }
  .ListedNFT__main-hero-txt_head span{
    font-size: 12px;
  }
  .ListedNFT__main-hero-txt_text{
    font-size: 8px;
  }
  .search-input-listed{
    width: 100%;
  }
  .ListedNFT__main-top{
    flex-direction: column;
  }
  .ListedNFT__main-top-dropdown{
    justify-content: end;
  }
  .search-input__img{
    left: 0%;
    padding-left: 42px;
  }
}
@media (max-width:425px) {
  .ListedNFT__main-hero-right{
    display: none;
  }
  .ListedNFT__main-cards{
    width: 100%;
  }
}

