.modal-whole-forget .modal{
  backdrop-filter: blur(11.050000190734863px) !important;
  --bs-modal-box-shadow: 0px 2px 12px 0px #27399314 !important;


}
.modal-whole-forget .main-section-wallet {
  border-radius: 0px 0px 15px 15px;
  border: none ;
}
.modal-whole-forget .modal-content {
  border-radius: 140px !important;
}

.modal-whole-forget .modal-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: none !important;
  justify-content: space-around;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  justify-content: flex-start !important;
}
.forgetpass__main {
  width: 100%;
  text-align: center;
  /* align-items: center;
      justify-content: center; */
}
.forgetpass__main h1 {
  font-family: "Montserrat";
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -1px;
  text-align: center;
  color: #0c1230;
  padding-bottom: 16px;
}

.forgetpass__main p {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: center;
  color: #464d6a;
  padding-bottom: 16px;
}

.forgotpass__email {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 16px !important; */
}
.email-label-contain {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 91px;
  padding-right: 90.66px;
}
.forgotpass__email h5 {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;

  text-align: left;
  color: #0c1230;
  width: 100%;

  margin-top: 50px;
}

.forgetpass__main button {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;
  width: 498px;
  border: transparent;
  height: 60px;
  background-color: #0f68ff;
  color: #ffff;
  margin-top: 32px;
  margin-bottom: 79px;
}

.forgotpass__email input {
  border: 1px solid transparent;
  width: 498px;
  height: 72px;
  box-shadow: 0px 2px 6px 0px #2739930f;
  padding-left: 24px;

  /* box-shadow: 0px -1px 2px 0px #2739930A; */

  border-radius: 20px;
  margin-top: 14px;
  /* margin-bottom: 32px; */
}
.email-label-contain input::placeholder{
  color: #464d6a;
  font-size: 18px;
  font-weight: 400;
}

.main-section-wallet-forget {
  display: flex;
  justify-content: flex-start;
  text-align: center;
}

.main-section-wallet-forget p {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.97px;
  cursor: pointer;
}
.main-section-wallet-forget p:hover{
  text-decoration: underline;
}

.close-btn-img {
  position: static !important;
  padding-right: 40px;
}

@media (max-width: 990px) {
  .forgotpass__email h5 {
    margin-left: 0;
  }
  .forgotpass__email input {
    width: 100%;
  }
  .forgetpass__main button {
    width: 300px;
  }
}

@media (max-width: 430px) {
  .forgetpass__main {
    width: 100% !important;
  }
  .forgetpass__main h1 {
    font-family: "Montserrat";
    font-size: 28px !important;
    font-weight: 600;
    line-height: 32.65px !important;
    letter-spacing: -0.54px !important;
    text-align: center;
    color: #0c1230;
    padding-bottom: 8.35px !important;
}
.forgetpass__main p {
  font-family: "Montserrat";
  font-size: 12px !important;
  font-weight: 400;
  line-height: 16.32px !important;
  text-align: center;
  color: #464d6a;
  padding-bottom: 15px !important;
}
.forgotpass__email h5 {
  font-family: "Montserrat";
  font-size: 12px !important;
  font-weight: 700;
  line-height: 10.88px !important;
  text-align: left;
  color: #0c1230;
  width: 100%;
  margin-top: 26px !important;
}
.forgotpass__email input {
  border: 1px solid transparent;
  width: 100% !important;
  height: 46px !important;
  box-shadow: 0px 2px 6px 0px #2739930f;
  padding-left: 13.06px !important;
  /* box-shadow: 0px -1px 2px 0px #2739930A; */
  border-radius: 10.88px !important;
  margin-top: 7.5px !important;
  margin-bottom: 28.34px !important;
}
.forgetpass__main button {
  font-family: "Montserrat";
  font-size: 10px !important;
  font-weight: 700 !important;
  line-height: 10.88px !important;
  text-align: center;
  border-radius: 10.88px !important;
  width: 100% !important;
  border: transparent;
  height: 46px !important;
  background-color: #0f68ff;
  color: #ffff;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.modal.show .modal-dialog {
  transform: none;
}
.email-label-contain input::placeholder{
  color: #464d6a;
  font-family: Montserrat;
  font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 10.88px !important;
  text-align: left;
}
.email-label-contain{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
}
@media (max-width:386px) {
  /* .modal-whole-forget {
    padding-right: 20px !important;
    padding-left: 20px !important;
} */
}
@media (max-width:360px) {
  /* .modal-whole-forget {
    padding-right: 15px !important;
    padding-left: 15px !important;
} */
}