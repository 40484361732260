p {
  padding: 0;
  margin: 0;
}

.dash-row-1 {
  padding-top: 32px;
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.total-balance-card {
  border-radius: 20px;
  position: relative;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;
  padding: 28px 24px;
  flex-direction: column;
  align-items: flex-start;

  gap: 24px;
  width: 40%;
}
.refresh-button {
  position: absolute;
  right: 3%;
  cursor: pointer;
}
.dash-heading {
  color: var(--Black-500---Primary, #1d1f2c);

  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 36.4px */
  letter-spacing: -1px;
}
.dash-subhead {
  color: var(--Grey-500---Primary, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  padding-top: 4px;
}
.nftId-main {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.total-balance-txt {
  color: var(--Grey-500---Primary, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.dollar-percent-contain {
  display: flex;
  align-items: center;
  gap: 25px;
  padding-bottom: 16px;
  padding-top: 4px;
  flex-wrap: wrap;
}
.dollar-value {
  color: var(--Black-500---Primary, #1d1f2c);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 41.6px */
  letter-spacing: -1px;
}
.percent-value {
  color: rgba(0, 194, 78, 0.84);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 22.4px */
  letter-spacing: -1px;
}
.updated-txt {
  color: var(--Grey-500---Primary, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.btns-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.Claim-btn {
  border-radius: 8px !important;
  background: var(--Blue-Text, #0f68ff) !important;
  display: flex !important;
  width: 148px !important;
  height: 48px !important;
  padding: 14px 24px !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--Black-0---Primary, #fff) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 130% !important; /* 18.2px */
  letter-spacing: 0.07px !important;
  text-transform: capitalize !important;
}

.buy-nft-btn {
  border-radius: 8px !important;
  border: 1px solid var(--Blue-Text, #0f68ff) !important;
  display: flex !important;
  width: 148px;
  height: 48px !important;
  padding: 14px 24px !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--Blue-Text, #0f68ff) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 130% !important; /* 18.2px */
  letter-spacing: 0.07px !important;
  text-transform: capitalize !important;
}

.info-div {
  border-radius: 8px;
  background: rgba(243, 174, 69, 0.12);
  display: flex;
  width: 100%;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
}

.info-txt {
  color: var(--Secondary-Yellow-500, #f3ae45);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.07px;
}

.web3-nfts-card {
  border-radius: 20px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;
  /* width: 736px; */
  padding: 28px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 60%;
}

.myweb-btn-contain {
  display: flex;

  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.nfts-txt {
  color: var(--Black-500---Primary, #1d1f2c);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
}

.select-nft-txt {
  color: var(--Grey-500---Primary, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  padding-top: 4px;
}

.buy-web3nft-btn {
  border-radius: 8px !important;
  background: var(--Blue-Text, #0f68ff) !important;
  display: flex !important;
  width: 189px;
  height: 36px !important;
  padding: 14px 12px !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--Black-0---Primary, #fff) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 130% !important; /* 18.2px */
  text-transform: capitalize !important;
  gap: 8px;
}
.bar-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.barchart-btm {
  padding-top: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
  padding-right: 50px;
  /* gap: 56px; */
}
.invested-container {
  display: flex;
  align-items: center;
  gap: 16px;
}
.invested-txt {
  color: var(--Grey-500---Primary, #667085);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
}
.invested-value {
  color: var(--Black-500---Primary, #1d1f2c);
  padding-top: 2px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -1px;
}
/* .cards-imgs {
  display: flex;
  align-items: center;
  gap: 18px;
  overflow-x: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  width:100%;
} */
::-webkit-scrollbar {
  display: none;
}

.bar-contain {
  border-radius: 20px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;
  padding: 28px 24px 35px 24px;
  flex-direction: column;
  width: 50%;
}
.linebar-contain {
  border-radius: 20px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;

  padding: 28px 24px 33px 24px;
  flex-direction: column;
  width: 50%;
}
.dropdown-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.both-dropdown-contain {
  display: flex;
  align-items: center;
  gap: 14px;
}

.dropdown-yearly {
  border-radius: 8px !important;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3) !important;
  background: var(--Black-0---Primary, #fff) !important;
  color: var(--Grey-500---Primary, #667085) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important; /* 22.4px */
}
.dropdown-toggle::after {
  background-repeat: no-repeat !important;
  border: none !important;
  content: url("/public/Images/Dashboard/arrow-down.svg") !important;
  vertical-align: middle !important;
}
.nft-growth-txt {
  color: var(--Black-500---Primary, #1d1f2c);

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
}

.view-txt {
  color: var(--Grey-500---Primary, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}

.row-2 {
  display: flex;

  gap: 24px;

  /* padding: 28px 24px; */
}
.row-3 {
  border-radius: 20px !important;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3) !important;
  background: var(--Black-0---Primary, #fff) !important;
  padding: 28px 24px;
  margin-top: 24px;
  margin-bottom: 110px;
}
.columns-name {
  border-bottom: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--grey-0-primary-screen-color, #f9f9fc);
}

.column-head {
  color: var(--Grey-500---Primary, #667085) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
}
.row-id {
  color: var(--Black-500---Primary, #1d1f2c) !important;

  font-size: 14px !important;
  font-style: normal !important ;
  font-weight: 500 !important;
  line-height: 160% !important; /* 22.4px */
}

.row-same {
  color: var(--Black-500---Primary, #1d1f2c) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 160% !important; /* 22.4px */
}

.table-main-head {
  color: var(--Black-500---Primary, #1d1f2c);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
}
.filter-btn {
  border: none;
  border-radius: 8px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Grey-500---Primary, #667085);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
}
.dropdown-week {
  border-radius: 8px !important;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3) !important;
  background: var(--Black-0---Primary, #fff) !important;
  display: flex !important;
  padding: 14px 20px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  color: var(--Grey-500---Primary, #667085) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 160% !important; /* 22.4px */
}

.table-view-img {
  display: flex;
  padding: 8px;
  /* gap:6px; */
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  background: var(--Black-0---Primary, #fff);
}
.flex-img {
  padding: 6px;
}
.table-top-lhs {
  display: flex;
  align-items: center;
  gap: 16px;
}

.table-above-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 28px;
}
.hr-above {
  border: 1px solid var(--grey-100-primary-outline-color, #f0f1f3);
  opacity: 1;
  margin-top: 0% !important;
}

/* cells padding */
/* .css-1ex1afd-MuiTableCell-root {
  padding: 16px 24px !important;
} */
.status-txt {
  border-radius: 8px;
  background: rgba(79, 190, 136, 0.12);
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  width: max-content;
}

.status-txt-warning {
  border-radius: 8px;
  background: #ffcccc;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  width: max-content;
}

.buy-nft-card {
  /* background: url("/public/Images/Dashboard/buy-nft-card-bg.svg"); */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 63px 64px 64px 75px; */
  /* width: 255px;
  height: 160px;*/
  gap: 10px;
  padding-top: 34.64px;
  padding-bottom: 34.64px;
 
}
.No-Nft-head {
  color: var(--Black-500---Primary, #1d1f2c);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  padding-top: 15px;
  
}
.no-nft-des{
  color: #667085;
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 15.6px */
}
/* .buy-nft-card p {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #667085;
} */
.buy-nft-card-btn {
  background: #0f68ff !important;
  color: #fff !important;
  width: 116px !important;
  height: 33px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 8px !important;
  border: transparent;
}
.nodata-contain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 141px;
  padding-bottom: 140px;
}
.nodata-txt {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  color: #1d1f2c;
  padding-top: 15px;
}

.nodata-des {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: center;
  color: #667085;
  padding-top: 10px;
}
@media (max-width: 1200px) {
  .dash-row-1 {
    flex-wrap: wrap;
  }
  .row-2 {
    flex-wrap: wrap;
  }
  .bar-contain,
  .linebar-contain {
    width: 100%;
  }
  .total-balance-card {
    width: 100%;
  }
  .web3-nfts-card {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .myweb-btn-contain {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
}
@media (max-width: 500px) {
  .barchart-btm {
    flex-direction: column;
  }
  .dropdown-text-container {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
  .table-above-row {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
  .btns-container {
    flex-wrap: wrap;
  }
  .btns-container button {
    width: 100% !important;
  }
}
@media (max-width:430px) {
  .nft-tiled-card img{
    width: 180px !important;
    height: 112.59px !important;
  }
}