h1 , h2 , h3 ,h4 , h5 , h6{
    margin: 0;
    padding: 0;
}


.ListedNFTnext__main {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 99.5px;
}
.ListedNFTnext__main-left {
  background-color: #0f68ff0d;
  max-width: 430px;
  height: 433px;
  border-radius: 15px;
  border: 1px solid #0f68ff33;
}
.ListedNFTnext__main-left-body {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.ListedNFTnext__main-left-body-right {
  display: flex;
}
.ListedNFTnext__main-left-body-right-txt {
  /* font-family: Montserrat; */
  font-size: 18px;
  font-weight: 600;
  /* line-height: 21.94px; */
  /* text-align: center; */
  color: #1d1f2c;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 9px;
}

.ListedNFTnext__main-right {
  max-width: 488px;
}
.ListedNFTnext__main-right-blueTick {
  display: flex;
  gap: 5px;
  padding-top: 6.5px;
  padding-bottom: 25px;
}
.ListedNFTnext__main-right-blueTick p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  /* line-height: 17.07px; */
  text-align: center;
  color: #1d1f2c;
}
.ListedNFTnext__main-right-head {
  /* font-family: Montserrat; */
  font-size: 36px;
  font-weight: 700;
  /* line-height: 43.88px; */
  text-align: left;
  color: #0f68ff;
  padding-bottom: 15px;
}
.ListedNFTnext__main-right-head-txt {
  /* font-family: Montserrat; */
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #667085;
}
.ListedNFTnext__main-right-head-txt span {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  /* line-height: 17.07px; */
  text-align: left;
  color: #1d1f2c;
}
.ListedNFTnext__main-right-body {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 35px;
}
.ListedNFTnext__main-right-body-txt {
  width: 65px;
  height: 22px;
  border-radius: 5px;
  border: 0.5px solid #e2e2e2;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  /* line-height: 14.63px; */
  text-align: left;
  color: #1d1f2c;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ListedNFTnext__main-right-body-text{
    /* font-family: Montserrat; */
font-size: 12px;
font-weight: 500;
/* line-height: 14.63px; */
text-align: left;
color: #667085;

}
.ListedNFTnext__main-right-body-sale{
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom:  1px solid #E2E5EF;
}
.ListedNFTnext__main-right-body-sale p{
    font-family: Montserrat;
font-size: 14px;
font-weight: 500;
/* line-height: 17.07px; */
text-align: left;
color: #000000;
}
.ListedNFTnext__main-right-body-bottom{
    /* height: 100%; */
    width: 100%;
    border-radius: 10px;
    border: 1px solid #E2E5EF
}
.ListedNFTnext__main-right-body-sale-head{
    padding-top: 20px;
    padding-left: 14px;
    padding-bottom: 25px;
}
.ListedNFTnext__main-right-body-sale-head p{
    /* font-family: Montserrat; */
font-size: 12px;
font-weight: 500;
/* line-height: 14.63px; */
text-align: left;
color: #667085;
padding-bottom: 15px;
}
.ListedNFTnext__main-right-body-sale-head h6{
    /* font-family: Montserrat; */
font-size: 24px;
font-weight: 600;
/* line-height: 29.26px; */
text-align: left;
color: #0F68FF;
}
.ListedNFTnext__main-right-body-sale-head-span{
    font-family: Montserrat;
font-size: 10px;
font-weight: 500;
/* line-height: 12.19px; */
text-align: left;
color: #667085;
}
.ListedNFTnext__btn{
    display: flex;
    gap: 10px;
    padding-left: 15px  ;
}

.ListedNFTnext__main-right-body-sale-btn1{
    width: 224px;
    height: 42px;
    border-radius: 8px;
    border: 1px solid var(--Blue-Text, #0F68FF);
    background-color: #0F68FF;
    font-family: Montserrat;
font-size: 14px;
font-weight: 600;
/* line-height: 18.2px; */
letter-spacing: 0.005em;
text-align: center;
color: #FFFFFF;
}
.ListedNFTnext__main-right-body-sale-btn2{
    width: 224px;
    height: 42px;
    border-radius: 8px;
    border: 1px solid var(--Blue-Text, #0F68FF);
    background: #0F68FF0D;
    font-family: Montserrat;
font-size: 14px;
font-weight: 600;
/* line-height: 18.2px; */
letter-spacing: 0.005em;
text-align: center;
color: #0F68FF;
}
.bottom-text{
    font-family: Montserrat;
font-size: 12px;
font-weight: 600;
/* line-height: 14.63px; */
text-align: left;
color: #1D1F2C;
padding-top: 20px;
padding-left: 15px;
padding-bottom: 20px;
}
.bottom-text span{
    font-family: Montserrat;
font-size: 12px;
font-weight: 500;
line-height: 14.63px;
text-align: left;
color: #667085;
}



@media (max-width:1024px) {
    .ListedNFTnext__main{
        flex-direction: column-reverse;
    }
}
@media (max-width:768px) {
    .ListedNFTnext__btn{
        flex-direction: column;
    }
}